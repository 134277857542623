import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import Navbar from '../Navbars/IndexNavbar';
import Footer from '../Footers/Footer';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/tailwind.css';
import '../../styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
  }
  return (
    <>
      <Navbar />
      {children}
      <ToastContainer />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
