import React from 'react';

export default function Footer() {
  return (
    <>
      <footer className='relative bg-gray-300 py-4'>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap items-center md:justify-between justify-center'>
            <div className='w-full md:w-4/12 px-4 mx-auto text-center'>
              <div className='text-sm text-gray-600 font-semibold py-1'>
                &copy; {new Date().getFullYear()} Site created and maintained by
                YDS Chicago.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
